import React from 'react';
import LoadingImg from '../../assets/icons/loading.gif';

import Image from 'next/image';

function Loading() {
    return (
        <div>
            <Image height={200} width={200} src={LoadingImg} alt="loading..." />
        </div>
    );
}

export default Loading;
