/* eslint-disable @next/next/no-img-element */
import React, { useRef, useState, useEffect, createRef, useMemo } from 'react';
import HoverVideoPlayer from 'react-hover-video-player';
import { logoutUser, receiveToken } from '../../redux/ActionCreators';
import { useDispatch } from 'react-redux';
import { handleTrackClick } from '../../shared/dataLayer';
import toast from 'react-hot-toast';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { baseUrl } from '@/shared/baseUrl';
import customImageLoader from '@/shared/customImageLoader';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import { IoMdVolumeHigh } from '@react-icons/all-files/io/IoMdVolumeHigh';
import { IoMdVolumeOff } from '@react-icons/all-files/io/IoMdVolumeOff';
const ProductCard = ({ product = null, isPriority = true, index }) => {
    const dispatch = useDispatch();
    const router = useRouter();
    const navigate = router.push;
    const [modalOpen, setModalOpen] = useState(false);
    const [muted, setMuted] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState('');
    const [isHovering, setIsHovering] = useState(false);

    const [isQuantity, setIsQuantity] = useState(false);
    const [selectedQuantity, setSelectedQuantity] = useState([false, false]);
    const [displayImage, setDisplayImage] = useState(true);

    const [showVideo, setShowVideo] = useState(false);

    const handleToggleMute = () => {
        setMuted(!muted);
    };

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = Math.floor(timeInSeconds % 60);
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    useEffect(() => {
        // let timer;
        // if (isHovering && duration) {
        //     timer = setInterval(() => {
        //         setCurrentTime((prevTime) => {
        //             if (prevTime + 0.01 < duration) {
        //                 return prevTime + 0.01;
        //             } else {
        //                 return 0;
        //             }
        //         });
        //     }, 10);
        // }
        // return () => clearInterval(timer);
    }, [isHovering, duration]);

    function calculatePreviousPrice(currentPrice, reductionPercentage) {
        var previousPrice = currentPrice / (1 - reductionPercentage / 100);
        return Math.round(previousPrice);
    }
    const addToCart = (product, variant, selectedQuantity) => {
        const existingCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
        const itemExists = existingCartItems.some(
            (item) => item.name === product?.name && item.variant === variant?.name
        );
        const newItem = {
            name: product?.name,
            image: product.images ? product.images[0] : null,
            variant: variant?.name,
            specialDelivery: variant?.special_delivery_price,
            price: variant?.price,
            quantity: selectedQuantity[1],
            productId: product._id,
            NBStock: variant?.NBStock,
            certified: product?.certified,
            warranty: product?.warranty,
            discount:
                variant?.discount > 0 && variant?.price
                    ? `${calculatePreviousPrice(variant?.price, variant?.discount)}`
                    : null,
        };
        if (itemExists) {
            const updatedCartItems = existingCartItems.map((item) => {
                if (item.name === product?.name && item.variant === variant?.name) {
                    return newItem;
                }
                return item;
            });
            localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
        } else {
            const updatedCartItems = [...existingCartItems, newItem];
            localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
        }
        if (!localStorage.getItem('token')) {
            toast.error('Veuillez vous connecter à votre compte pour poursuivre vos achats.');
            navigate('/connexion');
        } else {
            const bearer = 'Bearer ' + localStorage.getItem('token');
            fetch(baseUrl + '/api/user/refresh/profile', {
                headers: { Authorization: bearer },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res?.token) {
                        localStorage.setItem('token', res.token);
                        localStorage.setItem('creds', JSON.stringify(res?.profile));
                        dispatch(receiveToken(res.token));
                        navigate('/checkout');
                    } else {
                        toast.error(`Votre session a expiré. Veuillez vous connecter à votre compte.`);
                        dispatch(logoutUser());
                        navigate('/connexion');
                    }
                })
                .catch(() => {
                    toast.error(`Votre session a expiré. Veuillez vous connecter à votre compte.`);
                    dispatch(logoutUser());
                    navigate('/connexion');
                });
        }
    };
    const handleSelectQuantity = (quantity, variant) => {
        setSelectedQuantity([variant, quantity]);
        setIsQuantity(false);
    };
    const handleLoadedMetadata = (event) => {
        // Extract the video duration from the event
        const videoElement = event.target;

        setDuration(videoElement.duration);

        // videoElement.addEventListner('pause', () => {
        //     console.log('paused');
        // });
    };
    const isMobileOrTablet = typeof window !== 'undefined' && window.innerWidth < 768;

    const elemRef = createRef(null);

    const handleShowVideo = () => {
        setShowVideo(true);
    };

    useIntersectionObserver(elemRef, handleShowVideo);
    const timeStamp = useMemo(() => new Date().getTime(), []);

    return (
        <>
            <div ref={elemRef} className="h-[600px] flex-grow flex flex-col flex-end shadow mb-2">
                {(index === 0 || showVideo) && (
                    <>
                        <div
                            className="rounded-xl h-[420px] relative rounded-b-none overflow-hidden opacity-90 "
                            onMouseEnter={() => {
                                if (!isMobileOrTablet) {
                                    setIsHovering((hovering) => true);
                                }
                            }}
                            onMouseLeave={() => {
                                if (!isMobileOrTablet) {
                                    setIsHovering((hovering) => false);
                                }
                            }}
                            onClick={() => {
                                setIsHovering((hovering) => !hovering);
                            }}
                        >
                            {isHovering && (
                                <div className="absolute rounded-xl justify-center bg-secondary z-50 right-0">
                                    <button
                                        className="bg-gray-800 text-white px-4 py-2 rounded-lg"
                                        onClick={() => {
                                            // const isMobileOrTablet = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
                                            // if (isMobileOrTablet) {
                                            //   if (product?.variants?.length > 0) {
                                            //     setModalOpen(true)
                                            //   } else {
                                            //     setModalOpen(true)
                                            //   }
                                            // } else {
                                            // handleSelectQuantityAndTrackClick(
                                            //   selectedQuantity,
                                            //   variant,
                                            //   product
                                            // );
                                            navigate(`/product/${product?._id}`);
                                            handleTrackClick(product);
                                            // }
                                        }}
                                    >
                                        J&apos;achète
                                    </button>
                                </div>
                            )}
                            {!isHovering && (
                                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center z-50">
                                    <div className="bg-gray rounded-full p-2 bg-opacity-60">
                                        <svg
                                            xmlns="https://www.w3.org/2000/svg"
                                            className="h-10 w-10 text-black"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                transform="scale(1.5) translate(-3, -4)"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M15 12l-6-4v8l6-4z"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            )}
                            {product?.video && (
                                <>
                                    {!isHovering && (
                                        <div className="h-full w-full relative">
                                            {/* <Image
                                                priority={true}
                                                // objectFit="contain"
                                                className="h-full"
                                                // loader={customImageLoader}
                                                src={`${product?.images[0]}`}
                                                alt={product?.name}
                                                fill
                                            /> */}
                                            <img
                                                // priority={true}
                                                // objectFit="contain"
                                                className="h-full w-full"
                                                // loader={customImageLoader}
                                                src={`${product?.images[0]}`}
                                                alt={product?.name}
                                                // fill
                                            />
                                        </div>
                                    )}
                                    {isMobileOrTablet ? (
                                        <HoverVideoPlayer
                                            preload="none"
                                            id="hover-video-player"
                                            currentTime={currentTime}
                                            onTimeUpdate={(e) => {
                                                const time = e.target.currentTime;
                                                if (time > 0) {
                                                    setCurrentTime((prevTime) => time);
                                                }
                                                // console.log(e.target.currentTime);
                                            }}
                                            videoSrc={product?.video ? `${product.video}?${timeStamp}` : null}
                                            style={{ width: '100%', height: '420px', position: 'relative' }}
                                            sizingMode="container"
                                            focused={isHovering}
                                            onLoadedMetadata={(e) => {
                                                setDisplayImage(false);
                                                handleLoadedMetadata(e);
                                            }}
                                            // pausedOverlay={<></>}
                                            muted={muted}
                                            // loadingOverlay={
                                            //     <Image
                                            //         priority={true}
                                            //         unoptimized={true}
                                            //         src={`${product?.images[0]}?${timeStamp}`}
                                            //         alt={product?.name}
                                            //         fill
                                            //     />
                                            // }
                                            unloadVideoOnPaused
                                        />
                                    ) : (
                                        <HoverVideoPlayer
                                            preload="none"
                                            id="hover-video-player"
                                            currentTime={currentTime}
                                            onTimeUpdate={(e) => {
                                                setDisplayImage(false);
                                                const time = e.target.currentTime;
                                                if (time > 0) {
                                                    setCurrentTime((prevTime) => time);
                                                }
                                                // console.log(e.target.currentTime);
                                            }}
                                            videoSrc={product?.video ? `${product.video}?${timeStamp}` : null}
                                            style={{ width: '100%', height: '420px', position: 'relative' }}
                                            sizingMode="container"
                                            onLoadedMetadata={(e) => {
                                                handleLoadedMetadata(e);
                                            }}
                                            // pausedOverlay={
                                            //     <Image
                                            //         src={`${product?.images[0]}?${timeStamp}`}
                                            //         unoptimized={true}
                                            //         alt={product?.name}
                                            //         fill
                                            //     />
                                            // }

                                            muted={muted}
                                            // loadingOverlay={
                                            //     <Image
                                            //         src={`${product?.images[0]}?${timeStamp}`}
                                            //         alt={product?.name}
                                            //         unoptimized={true}
                                            //         fill
                                            //     />
                                            // }
                                            unloadVideoOnPaused
                                        />
                                    )}
                                </>
                            )}
                        </div>
                        <div className="overlay-container bg-lightGray bg-opacity-50 flex justify-between pl-4 pr-4 items-center">
                            <div className="overlay-text text-sm w-14">{formatTime(currentTime)}</div>
                            <div className="mx-4 relative h-[6px] w-full bg-[rgba(0,0,0,0.5)]">
                                <div
                                    className="progress-bar-fill absolute z-50"
                                    style={{
                                        width: `${(currentTime / (duration || 10)) * 100}%`,
                                        height: '6px',
                                        backgroundColor: '#FF6600',
                                        opacity: 1,
                                    }}
                                ></div>
                            </div>
                            <div>
                                <div className="mute-button cursor-pointer py-2" onClick={handleToggleMute}>
                                    {muted ? <IoMdVolumeOff size={25} /> : <IoMdVolumeHigh size={25} />}
                                </div>
                            </div>
                        </div>
                        <div
                            className="flex-grow flex flex-col  p-4"
                            onClick={() => {
                                navigate(`/product/${product?._id}`);
                                handleTrackClick(product);
                                // }
                            }}
                        >
                            <div className="flex-grow flex flex-col  h-full justify-between gap-2">
                                <div>
                                    <p className="text-sm ellipsis-3">
                                        {' '}
                                        {product?.shortName ? product?.shortName : product?.name}
                                    </p>
                                </div>
                                <div>
                                    {/* <p className="line-through text-slate-400">95,99 CFA</p> */}
                                    <p className="text-lg text-red">
                                        {' '}
                                        {new Intl.NumberFormat('fr-FR').format(product?.price)} CFA
                                    </p>
                                </div>
                            </div>
                        </div>
                        {modalOpen && (
                            <div
                                className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-lightGray bg-opacity-70"
                                onClick={() => setModalOpen(false)}
                            >
                                <div
                                    className="w-11/12 h-3/4 bg-lightGray rounded-lg shadow-lg"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    {product.variants.length > 0 ? (
                                        <div className="flex flex-wrap justify-center p-4 items-center flex-col h-full">
                                            {product.variants.map((variantTemp, index) => (
                                                <div key={index} className="flex w-full justify-between items-center">
                                                    <div>
                                                        <button
                                                            key={index}
                                                            disabled={!variantTemp?.inStock}
                                                            onClick={() => {
                                                                if (selectedQuantity[1] > 0) {
                                                                    addToCart(product, variantTemp, selectedQuantity);
                                                                }
                                                            }}
                                                            className={`${
                                                                selectedQuantity[0]?.name === variantTemp.name &&
                                                                selectedQuantity[0]?.variant === variantTemp.variant
                                                                    ? 'text-secondary bg-yellow'
                                                                    : null
                                                            } ${
                                                                variantTemp?.inStock
                                                                    ? 'text-black bg-white-500 border-secondary hover:bg-yellow'
                                                                    : 'text-white bg-gray border-gray'
                                                            } border m-5 rounded-full text-black py-2 px-4 m-1 text-md font-medium`}
                                                        >
                                                            {variantTemp?.name}
                                                        </button>
                                                    </div>
                                                    <div className="flex items-end gap-1 relative">
                                                        <p
                                                            className={`${
                                                                selectedQuantity[0]?.name === variantTemp.name &&
                                                                selectedQuantity[0]?.variant === variantTemp.variant
                                                                    ? 'text-secondary font-bold'
                                                                    : null
                                                            }`}
                                                        >
                                                            Quantité:{' '}
                                                        </p>
                                                        <div
                                                            onClick={() => setIsQuantity(variantTemp)}
                                                            className="flex items-center justify-center border border-lightestGray rounded-sm pr-1 pl-1.5 pt-0.5 gap-2.5 w-9 shadow-sm cursor-pointer"
                                                        >
                                                            {selectedQuantity[0]?.name === variantTemp.name &&
                                                            selectedQuantity[0]?.variant === variantTemp.variant
                                                                ? selectedQuantity[1]
                                                                : 0}
                                                            <img
                                                                rel="preload"
                                                                src="/images/icons/arrowDown.svg"
                                                                alt=""
                                                            />
                                                        </div>
                                                        {isQuantity?.name === variantTemp.name &&
                                                            isQuantity?.variant === variantTemp.variant && (
                                                                <div className="absolute flex flex-col items-center justify-center bg-white top-8 left-[77px] border border-lightestGray rounded-sm pr-1 pl-1.5 pt-0.5 gap-x-2.5 w-9 shadow-xl cursor-pointer z-50">
                                                                    {Array.from(
                                                                        {
                                                                            length: Math.min(
                                                                                variantTemp?.NBStock || 0,
                                                                                10
                                                                            ),
                                                                        },
                                                                        (_, index2) => (
                                                                            <p
                                                                                key={index2}
                                                                                onClick={() =>
                                                                                    handleSelectQuantity(
                                                                                        index2 + 1,
                                                                                        variantTemp
                                                                                    )
                                                                                }
                                                                            >
                                                                                {index2 + 1}
                                                                            </p>
                                                                        )
                                                                    )}
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                            ))}
                                            <button
                                                onClick={() => {
                                                    if (selectedQuantity[1] > 0) {
                                                        addToCart(product, selectedQuantity[0], selectedQuantity);
                                                    }
                                                }}
                                                className={`border m-5 rounded-full text-black py-2 px-4  text-md font-medium hover:bg-yellow`}
                                            >
                                                Confirmer
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="flex flex-wrap justify-center p-4 items-center flex-col h-full">
                                            <div className="flex w-full justify-between items-center">
                                                <div>
                                                    <button
                                                        disabled={!product?.inStock}
                                                        onClick={() => {
                                                            if (selectedQuantity[1] > 0) {
                                                                addToCart(product, product, selectedQuantity);
                                                            }
                                                        }}
                                                        className={` ${
                                                            product?.inStock
                                                                ? 'text-black bg-white-500 border-secondary hover:bg-yellow'
                                                                : 'text-white bg-gray border-gray'
                                                        } border m-5 rounded-full text-black py-2 px-4  text-md font-medium`}
                                                    >
                                                        Produit
                                                    </button>
                                                </div>
                                                <div className="flex items-end gap-1 relative">
                                                    <p className={`${true ? 'text-secondary font-bold' : null}`}>
                                                        Quantité:{' '}
                                                    </p>
                                                    <div
                                                        onClick={() => setIsQuantity(product)}
                                                        className="flex items-center justify-center border border-lightestGray rounded-sm pr-1 pl-1.5 pt-0.5 gap-2.5 w-9 shadow-sm cursor-pointer"
                                                    >
                                                        {selectedQuantity[1] || 0}
                                                        <img rel="preload" src="/images/icons/arrowDown.svg" alt="" />
                                                    </div>
                                                    {
                                                        <div className="absolute flex flex-col items-center justify-center bg-white top-8 left-[77px] border border-lightestGray rounded-sm pr-1 pl-1.5 pt-0.5 gap-x-2.5 w-9 shadow-xl cursor-pointer z-50">
                                                            {Array.from(
                                                                { length: Math.min(product?.NBStock || 0, 10) },
                                                                (_, index2) => (
                                                                    <p
                                                                        key={index2}
                                                                        onClick={() =>
                                                                            handleSelectQuantity(index2 + 1, false)
                                                                        }
                                                                    >
                                                                        {index2 + 1}
                                                                    </p>
                                                                )
                                                            )}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default ProductCard;
