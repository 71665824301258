import { useEffect, useState } from 'react';

function useIntersectionObserver(ref, handleOnVisible) {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const currentRef = ref.current;
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
            },
            { threshold: 0.1 }
        );
        if (currentRef) {
            observer.observe(currentRef);
        }
        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [ref]);

    useEffect(() => {
        if (isVisible) {
            handleOnVisible();
        }
    }, [isVisible, handleOnVisible]);
}

export default useIntersectionObserver;
